import * as _ from "lodash";
import { call, put } from 'redux-saga/effects';
import { errorFinished, errorTrigger } from "src/store/ErrorRedux";
import { store } from "../store/index";
const isConnected = true;

function* callServer(apiFunction, reqData, showError = false, id = null) {
  if (isConnected) {
    const response = yield call(apiFunction, reqData, reqData.id || id);
    const {status = ''} = response || {};
    let resData = _.get(response, 'data', {});
    resData = resData || {};
    if (!_.isEmpty(resData.error_message)) {
      throw resData;
    } else if (status === 200) {
      return resData || {};
    } else if (status === 204) {
      return {resData};
    } else if (status == 201) {
      return resData;
    } else if (status == 401) {
      store.dispatch({ type: "LogoutUserCalledWithoutNavigation", payload: {} })
      console.log("UNAUTHORIZED")
      throw { error: true, statusCode:401 ,message:'Unauthorized'};
    } else {
      const {error = '', message = ''} = resData || {};
      let errorPayload = {};
      if (typeof error === 'object') {
        errorPayload = {...error};
      } else {
        errorPayload = {error, message};
      }
 
      if (showError) {
        if (response.problem === 'TIMEOUT_ERROR') {
          //alert(ERRORS.SERVER_NOT_RESPONDING);
        }
        else if (!_.isEmpty(errorPayload.message)){
          //yield put(errorTrigger(true))
          if (errorPayload.message === 'EmailUsed') {
            // showToast(ERRORS.EMAIL_USED);
            // yield put(errorFinished(errorPayload.message))
          } 
          else if (errorPayload.message === 'password should not be empty') {
            // showToast(ERRORS.PASSWORD_EMPTY);
            // yield put(errorFinished(errorPayload.message))
          } 
          else if (errorPayload.message === 'email must be an email') {
            // showToast(MESSAGES.ENTER_INVALID);
            // yield put(errorFinished(errorPayload.message))
          } 
          else if (errorPayload.message === 'INVALID_PASSWORD') {
            // yield put(signInFail(errorPayload.message));
            // yield put(errorFinished(errorPayload.message))
            // showToast(MESSAGES.PASSWORD_INVALID);
          } 
          else if (errorPayload.message === 'usernotexist') {
            // showToast(ERRORS.USER_NOT_EXIST);
            // yield put(errorFinished(errorPayload.message))
          } 
          else if (errorPayload.message === 'emailnotexist') {
            // showToast(ERRORS.USER_NOT_EXIST);
            // yield put(errorFinished(errorPayload.message))
          } 
          else if (errorPayload.message === 'mobilenotexist') {
            // showToast(ERRORS.MOBILE_NOT_EXIST);
            // yield put(errorFinished(errorPayload.message))
          } 
          else if (errorPayload.message === 'userNameEmpty') {
            // showToast(MESSAGES.USERNAME_EMPTY);
            // yield put(errorFinished(errorPayload.message))
          } 
          else if (errorPayload.message === 'emailNotVerified') {
            // showToast('Email is not verified');
            // yield put(errorFinished(errorPayload.message))
          } 
          else if (errorPayload.message === 'limitexceed') {
           console.log('OTP attempt limit exceeded. Kindly Try after 2 hours.');
           yield put(errorFinished(errorPayload.message))
          }
          else if (errorPayload.message === 'already viewd this post') {}
          else if (errorPayload.message === 'POST_NOT_FOUND') {}
          else {
            // console.log(errorPayload.message , "APICALLER");
            yield put(errorTrigger(true))
            yield put(errorFinished(errorPayload.message))
            if(errorPayload.message === 'Internal server error'){
              store.dispatch({ type: "LogoutUserCalled", payload: {} })
              console.log("SOME ERROR, THIS REDIRECTION CAN BE FIXED IN APICALLER.JS FILE")
              throw{ error: true, statusCode:401, message:'Unauthorized'};
            }
          }
        }
      }
      throw errorPayload;
    }
  } 
  else {
    if (showError) {
      //console.log(ERRORS.NETWORK_ERROR);
    }
    // eslint-disable-next-line no-throw-literal
    //throw {error: true, message, statusCode: 503};
  }
}

export default {
  callServer,
};
