import { createSlice } from "@reduxjs/toolkit";
import * as _ from "lodash";

const userslice = createSlice({
  name: "user",
  initialState: {
    userInfo: null,
    signUpLoading: false,
    signUpError: "",
    otpSend: false,

    otpverifyloading: false,

    signInLoading: false,
    signInError: "",
    signInRole: "user",

    auth: false,

    passwordChangedLoading : false,
    passwordChanged : null,
    passwordChangeError : '',

    infoLoading : false,
    counts : null ,

    forgotPasswordLoading: false,
    forgotPasswordError: "",
    forgetdone: false,

    profileloading: false,
    profileerror: false,

    personalInfo: null,

    editUserLoading: false,
    editUserError: "",
    userNameCreated: false,

    myLikesIdLoading : false,
    myLikesId: [],

    postILikedLoading: false,
    postILiked: [],
    postILikedError: "",
    postLikes : 0,

    commentILikedLoading : false,
    commentILiked : null,
    commentILikedError : '',

    deleteUserloading: false,
    deleteUserError: "",

    followLoading : false,
    follow : null,
    followCompleted : false,
    unFollowLoading : false,
    unFollow : null,
    unFollowCompleted : false,
    
    myFollowingIdLoading : false,
    myFollowingId : [],

    myFollowingLoading : false,
    myFollowing : null,
    myFollowingError : '',
    myFollowersLoading : false,
    myFollowers : null,
    myFollowersError : '',

    othersProfileLoading : false,
    othersProfile : null,

    aboutInfo: null,
    AboutdetailsLoading: false,
    AboutdetailsError: "",

    updateProfileImageError: "",
  },

  reducers: {
    //  SIGNING UP REDUCERS
    signUpTrigger: (state, action) => {
      return {
        ...state,
        signUpLoading: action.payload,
        signupfinish: false,
        signInError: "",
      };
    },
    signUpSuccess: (state, action) => {
      return {
        ...state,
        userInfo: { ...action.payload },
        signInError: "",
        otpSend : true
      };
    },
    signUpFail: (state, action) => {
      return {
        ...state,
        signUpError: action.payload,
      };
    },


    // PASSWORD CHANGE
    changePasswordTrigger: (state, action) => {
      return {
        ...state,
        changePasswordLoading: action.payload,
      };
    },
    changePasswordSuccess: (state, action) => {
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload.accessToken },
      };
    },
    changePasswordFail: (state, action) => {
      return {
        ...state,
        changePasswordError: action.payload,
      };
    },

    //  LOGIN REDUCERS
    signInSuccess: (state, action) => {
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload },
        auth: true,
      };
    },
    
    signInTrigger: (state, action) => {
      return {
        ...state,
        signInLoading: action.payload,
      };
    },

    signInFail: (state, action) => {
      return {
        ...state,
        signInError: action.payload,
      };
    },

    // LOGOUT
    logoutUserSuccess: (state, action) => {
      return { };
    },

    //  EDIT USERNAME REDUCERS
    editUserNameTrigger: (state, action) => {
      return {
        ...state,
        editUserLoading: action.payload,
        userNameCreated: true,
      };
    },

    // Edit User Info
    editUserNameSuccess: (state, action) => {
      return {
          ...state,
          userInfo: action.payload,
      };
    },
    
    editUserNameFail: (state, action) => {
      return {
        ...state,
        editUserError: action.payload,
      };
    },

    

    //  RESETTING PASSWORD REDUCERS
    forgotPasswordTrigger: (state, action) => {
      return {
        ...state,
        forgotPasswordLoading: action.payload,
        signupfinish: false,
      };
    },
    forgotPasswordSuccess: (state, action) => {
      return {
        ...state,
        forgetdone: true,
        userInfo: action.payload,
      };
    },
    forgotdone: (state, action) => {
      return {
        forgetdone: false,
      };
    },
    forgotPasswordFail: (state, action) => {
      return {
        forgotPasswordError: action.payload,
      };
    },

    
    // FORGET PASSWORD CHANGE
    passwordChangeTrigger: (state, action) => {
      return {
        ...state,
        passwordChangeLoading: action.payload,
      };
    },

    passwordChangeSuccess : (state , action) => {
      return {
        ...state,
        passwordChange: action.payload,
      };
    },

    passwordChangeFail : (state , action) => {
      return {
        ...state,
        passwordChangeError: action.payload,
      };
    },

    //  OTP VERIFING REDUCERS
    OTPVerifyTrigger: (state, action) => {
      return {
        ...state,
        otpverifyloading: action.payload,
      };
    },
    OTPSuccess: (state, action) => {
      return {
        ...state,
        signupfinish: true,
        userInfo: { ...state.userInfo, ...action.payload },
        otpverifyloading: false,
        auth:true
      };
    },

    // OTP Resend
    resendOtpTrigger: (state, action) => {
      return {
        ...state,
        resendotplaoding: action.payload,
      };
    },


    
    // PROFILE REDUCERS
    profileTrigger: (state, action) => {
      return {
        ...state,
        profileloading: action.payload,
      };
    },
   
    profileSuccess: (state, action) => {
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload },
      };
    },
  
    profileFail: (state, action) => {
      return {
        ...state,
        profileerror: action.payload,
      };
    },

    // EDITING USER INFO REDUCERS
    editUserTrigger: (state, action) => {
      return {
        ...state,
        editUserLoading: action.payload,
      };
    },
    editUserSuccess: (state, action) => {
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload },
      };
    },
    editUserFail: (state, action) => {
      return {
        ...state,
        editUserError: action.payload,
      };
    },

    // UPDATING PROFILE IMAGE REDUCERS
    updateProfileImageSuccess: (state, action) => {
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload },
      };
    },
    updateProfileImageTrigger: (state, action) => {
      return {
        ...state,
        editUserLoading: action.payload,
      };
    },

    // LIKE ID REDUCERS
    myLikedPostTrigger : (state , action) => {
      return {
        ...state,
        myLikesIdLoading: action.payload,
      };
    },

    likesmyId: (state, action) => {
      return {
        ...state,
        myLikesId: action.payload,
      };
    },

    likesIdSuccess: (state, action) => {
      let updatedList = [];
      if (action.payload.type == "like") {
        updatedList = [...state.myLikesId, { postId: action.payload.postId }];
      } else {
        _.map(state.myLikesId, (item) => {
          if (item.postId !== action.payload.postId) {
            updatedList.push(item);
          }
        });
      }
      return {
        ...state,
        myLikesId: updatedList,
      };
    },

    postLikesSuccess: (state, action) => {
      return {
        ...state,
        postLikes: action.payload,
      };
    },

    //NOT FOR FINAL APP
    // DELETING USER FOR DATABASE
    deleteUserTrigger: (state, action) => {
      return {
        ...state,
        deleteUserloading: action.payload,
      };
    },
    deleteUserSuccess: (state, action) => {
      return {
        ...state,
        userInfo: null,
      };
    },
    deleteUserFail: (state, action) => {
      return {
        ...state,
        deleteUserError: action.payload,
      };
    },
  },
});

export default userslice.reducer;
export const {
  signUpTrigger,
  signUpSuccess,
  signUpFail,

  OTPVerifyTrigger,
  OTPSuccess,
  resendOtpTrigger,

  signInTrigger,
  signInSuccess,
  signInFail,
  signInFulfil,

  forgotPasswordTrigger,
  forgotPasswordSuccess,
  forgotPasswordFail,

  passwordChangeTrigger,
  passwordChangeSuccess,
  passwordChangeFail,

  changePasswordFail,
  changePasswordSuccess,
  changePasswordTrigger,

  profileTrigger,
  profileSuccess,
  profileFail,

  infoTrigger,
  getInfo,

  editUserTrigger,
  editUserSuccess,
  editUserFail,

  deleteUserTrigger,
  deleteUserSuccess,
  deleteUserFail,

  updateProfileImageSuccess,
  updateProfileImageTrigger,

  logoutUserSuccess,
  forgotdone,

  AboutdetailsError,
  AboutdetailsFail,
  AboutdetailsLoading,
  AboutdetailsSuccess,
  AboutdetailsTrigger,

  clearloaderSuccess,

} = userslice.actions;



    // // USER INFO
    // infoTrigger : (state , action) => {
    //   return {
    //     ...state,
    //     infoLoading : action.payload,
    //   };
    // },

    // getInfo : (state , action) => {
    //   return {
    //     ...state,
    //     counts : action.payload,
    //   };
    // },

    // // Other User Profile
    // othersInfoTrigger : (state , action) => {
    //   return {
    //     ...state,
    //     othersProfileLoading : action.payload,
    //   };
    // },

    // othersInfo : (state , action) => {
    //   return {
    //     ...state,
    //     othersProfile : action.payload,
    //   };
    // },



    //POST DATA I LIKED
    // postILikedTrigger: (state, action) => {
    //   return {
    //     ...state,
    //     postILikedLoading: action.payload,
    //   };
    // },

    // postILikedSuccess: (state, action) => {
    //   return {
    //     ...state,
    //     postILiked: action.payload,
    //   };
    // },

    // postILikedFail: (state, action) => {
    //   return {
    //     ...state,
    //     postILikedError: action.payload,
    //   };
    // },

    // COMMENT I LIKED
    // POST DATA I LIKED
    // commentILikedTrigger: (state, action) => {
    //   return {
    //     ...state,
    //     commentILikedLoading: action.payload,
    //   };
    // },

    // commentILikedSuccess: (state, action) => {
    //   return {
    //     ...state,
    //     commentILiked: action.payload,
    //   };
    // },

    // commentILikedFail: (state, action) => {
    //   return {
    //     ...state,
    //     commentILikedError: action.payload,
    //   };
    // },

    // // FOLLOW
    // followTrigger : (state , action) => {
    //   return {
    //       ...state,
    //       followLoading: action.payload,
    //     };
    // },
    // followSuccess : (state , action) => {
    //   return {
    //       ...state,
    //       follow: action.payload,
    //     };
    // },
    // followFullFil : (state , action) => {
    //   return {
    //     ...state,
    //     followCompleted: action.payload,
    //   };
    // },

    
    // // UNFOLLOW
    // unFollowTrigger : (state , action) => {
    //   return {
    //       ...state,
    //       unFollowLoading: action.payload,
    //     };
    // },
    // unFollowSuccess : (state , action) => {
    //   return {
    //       ...state,
    //       unFollow: action.payload,
    //     };
    // },
    // unFollowFullFil : (state , action) => {
    //   return {
    //     ...state,
    //     unFollowCompleted: action.payload,
    //   };
    // },

    // // USER FOLLOWING & FOLLOWERS
    // getFollowingIdTrigger: (state, action) => {
    //   return {
    //     ...state,
    //     myFollowingIdLoading: action.payload,
    //   };
    // },

    // getFollowingIdSuccess: (state, action) => {
    //   return {
    //     ...state,
    //     myFollowingId: action.payload,
    //   };
    // },


    // getFollowingTrigger: (state, action) => {
    //   return {
    //     ...state,
    //     myFollowingLoading: action.payload,
    //   };
    // },

    // getFollowingSuccess: (state, action) => {
    //   return {
    //     ...state,
    //     myFollowing: action.payload,
    //   };
    // },
    // getFollowingFail: (state, action) => {
    //   return {
    //     ...state,
    //     myFollowingError: action.payload,
    //   };
    // },

    // getFollowerTrigger: (state, action) => {
    //   return {
    //     ...state,
    //     myFollowersLoading: action.payload,
    //   };
    // },

    // getFollowerSuccess: (state, action) => {
    //   return {
    //     ...state,
    //     myFollowers: action.payload,
    //   };
    // },

    // getFollowerFail: (state, action) => {
    //   return {
    //     ...state,
    //     myFollowersError: action.payload,
    //   };
    // },