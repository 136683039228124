import "./CustomButton.css"

function CustomButton({onClick , buttonText , small , Disabled}) {
  return (
    <div className='form-button'>
    <button 
       className={small ? 'custom-button-small' :'custom-button'} 
       type='submit' 
       onClick={onClick}
       disabled={Disabled}
    >
      <p 
         className="button-text"
      >{buttonText}
      </p>
    </button>
    </div>
  )
}

export default CustomButton