import "./CustomInput.css";

function CustomInput({text, label , placeholder , value , onChange , Error , onFocus}) {
  console.log(Error , "Error")
  return (
    <div className="input">
      <label className='label-text' htmlFor={label}>{text}</label>
      <input className='inputfield' name={label} placeholder={placeholder} type={label} value={value} onChange={onChange} onFocus={onFocus}/>
      {Error && <p className="input-error">{Error == "EMAIL_USED" ? " " : "Enter a Valid Email"}</p>}
    </div> 
  )
}



export default CustomInput