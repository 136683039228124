import apisauce from "apisauce";

const baseUrl = "https://galaxiapi.weedcommerce.info/api";


const create = (baseURL = baseUrl) => {
  const apis = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
    },
    timeout: 200000,
  });

  //--------- ONBOARDING API CALLS----------------------------------------------------------------
  const signUp = (data) => apis.post("/auth/signup", data);

  const signIn = (data) =>  apis.post("/auth/signin", data);

  const verifyotp = ({ email, Otp }) => apis.get(`/auth/confirm-code?email=${email}&token=${Otp}`);

  const resendOtp = (data) => apis.post("/auth/resendOtp", data);
    
  const forgotpassword = (data) => apis.post(`/auth/request-password-reset`, data);

  const setnewpassword = (data) => apis.post("/auth/set-new-passwordEmail", data);

  const getCountries = () => apis.get("/auth/countries");

  //------------------ USER DATA API CALLS -------------------------------------------------
  const getUser = (data) => {
    return apis.get(`/user/my-profile`,{},
    { headers: { Authorization: `Bearer ${data.token}` }}
    ); 
  };

  const getInfo = ({ userId , token}) => { 
    return apis.get(
      `/users/home/getCounts/${userId}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }

  const editProfile = (data) => {
    const { token } = data;
    delete data["token"];
    return apis.patch(`/user/my-profile`,{...data },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };

  const profilePicture = (data) => {
    const { payload, token } = data;
    const formData = new FormData();
    formData.append("image", payload);
    return apis.post(`/user/profile-image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  const updateProfilePicture = (data) => {
    const { image, token } = data;
    const formData = new FormData();
    formData.append("image", image);

    return apis.post(`/user/profile-image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        Accept: "Application/json",
      },
    });
  };

  const deleteProfilePicture = ({ token }) => {
    return apis.patch(
      `/user/remove-profile-image`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };

//   //-------------- FEED RELATED API CALLS ----------------------------------------
//     const getFollowerFeed = (data) => {
//       return apis.get(
//         `/users/home/myFollowingfeed` , {},
//         { headers: { Authorization: `Bearer ${data.token}` } }
//       ) 
//     }
  
//     const getMyFeed = ({skip , limit , token}) => {
//       //console.warn(skip, limit)
//       return apis.get(
//         `/users/home/myfeed?skip=${skip}&limit=${limit}&orderBy=id&sort=DESC`,
//         {},
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//     };

//   //---------------------- POST DATA API CALLS ----------------------------------------------
//   const getMyPost = (data) => {
//     return apis.get(`/post/getMyPost1?relations=["postImages","root","user"]`,
//     {},
//     { headers: { Authorization: `Bearer ${data.token}` } }
//     );
//   };

//   const getPostInfo = ({id , token}) => {
//     return apis.get(`/post/getuserPostsCounts/${id}`,
//     {},
//     { headers: { Authorization: `Bearer ${token}` } }
//     );
//   };

//   //--------------- POST RELATED API -------------------------------------------------
//   const createPost = (data) => {
//     const formData = new FormData();
//     if (data && data.postType == "video") {
//       console.log(data.videoUri);
//       formData.append("postType", "video");
//       formData.append("video", {
//         name: `name.mp4`,
//         uri: Platform.OS=='ios'? `file://${data.videoUri}` : `file://${data.videoUri}`,
//         type: data.mime,
//       });
//       formData.append("images" , {
//         name : data.thumbnail.name,
//         uri : data.thumbnail.uri,
//         type : data.thumbnail.mime,
//       })
//     }
//     if (data && data.hashTag) 
//     for (let i = 0; i < data.hashTag.length; i++) {
//       formData.append("hashtags", data.hashTag[i]);
//     }
//     if(data && data.hashTagId)
//     for(let i = 0; i < data.hashTagId.length; i++){
//       formData.append("hashTagIds" , data.hashTagId[i])
//     }
//     formData.append("description", data.description);
//     formData.append("videoTime" , data.videoTime)
//     formData.append("privacy", data.privacy);
//     formData.append('enable' , data.comment);
//     formData.append('videoName' , data.videoName);
//     formData.append('from', data.from);
//     return apis.post(`/post/createposts`, formData, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//         Authorization: `Bearer ${data.token}`,
//       },
//       onUploadProgress: data => {
//         //Set the progress value to show the progress bar
//         progresss = Math.round(data.loaded / data.total)
//         //console.warn(Math.round((100 * data.loaded) / data.total), " OOOOOOOOOOOOOOOOOOOO")
//       },
//     });
//   };

//   const editPost = (data) => {
//     const formData = new FormData();
//     for (let i = 0; i < data.banner.length; i++) {
//       formData.append("images", data.banner[i]);
//     }
//     if (data && data.postType == "Video") {
//       formData.append("postType", "video");
//       formData.append("videoLinks", data.videoLinks);
//     }
//     formData.append("description", data.description);
//     return apis.patch(
//       `/post/patchposts/${data.id}?relations=["postImages"]&deleteIds=[${data.deleteids}]`,
//       formData,
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           Authorization: `Bearer ${data.token}`,
//           Accept: "Application/json",
//         },
//       }
//     );
//   };

//   const deletePost = ({ id  , token }) => {
//     return apis.delete(
//       `/post/post/${id}`,
//       {},
//       { headers: { Authorization: `Bearer ${token}` } }
//     );
//   };

//   //------ POST LIKE API CALLS ---------------------------------
//   const likePost = (data) => {
//     // console.log(data , 'API LIKEPOST')
//     return apis.post(
//       `/post/like/${data.postId}`,
//       {},
//       { headers: { Authorization: `Bearer ${data.token}` } }
//     );
//   };

//   const postLikeCountById = (data) => {
//     //console.log(data , 'API LIKECOUNTPOST')
//     return apis.get(
//       `/post/like/postlikeCount/${data.postId}`,
//       {},
//       { headers: { Authorization: `Bearer ${data.token}` } }
//     );
//   };

//   const getPostById = (data) => {
//     return apis.get(
//       `/post/${data.postId}?relations=["postImages","root","user"]`,
//       {},
//       { headers: { Authorization: `Bearer ${data.token}` } }
//     );
//   };

//   //--------------- POST COMMENT RELATED API CALLS -------------------------------------------------------
//   const getCommentLike = (data) => {
//     return apis.get(
//       `/comment/like/${data.commentId}`,
//       {},
//       { headers: { Authorization: `Bearer ${data.token}` } }
//     )
//   }

//   const getCommentLikeCount = (data) => {
//     return apis.get(
//       `/comment/like/commentlikeCount/${data.commentId}`,
//       {},
//       { headers: { Authorization: `Bearer ${data.token}` } }
//     )
//   }
  
//   const getProfileLikeComment = (data) => {
//     return apis.get(
//       `/comment/like/profile/${data.commentId}`,
//       {},
//       { headers: { Authorization: `Bearer ${data.token}` } }
//     )
//   }

//   const likeComment = (data) => {
//     return apis.post(
//       `/comment/like/${data.commentId}`,
//       {},
//       { headers: { Authorization: `Bearer ${data.token}` } }
//     );
//   }

//   const getLikedCommentId = (data) => {
//     return apis.get(
//       `/comment/like/myCommentlikesId`,
//       {},
//       { headers: { Authorization: `Bearer ${data.token}` } }
//     )
//   }
  
//   const getCommentById = (data) => {
//     return apis.get(
//       `/post/comment/${data.id}?skip=0`,
//       {},
//       { headers: { Authorization: `Bearer ${data.token}` } }
//     );
//   };

//   const getCommentCount = (data) => {
//     return apis.get(
//       `/post/comment/commentcount/${data.id}`,
//       {},
//       { headers: { Authorization: `Bearer ${data.token}` } }
//     );
//   };

//   const postComment = (data) => {
//     return apis.post(`/post/comment/${data.id}`, data, {
//       headers: { Authorization: `Bearer ${data.token}` },
//     });
//   };

//   const deleteComment = (data) => {
//     return apis.delete(`/post/comment/${data.commentId}` ,  data, {
//       headers: { Authorization: `Bearer ${data.token}` },
//     });
//   }

//   const getCommentReply = (data) => {
//     // console.warn(data.commentId + "OHNO")
//     return apis.get(`/post/comment/replies/${data.commentId}`,{}, {
//       headers: { Authorization: `Bearer ${data.token}` },
//     })
//   }

//   const replyCommentCount = (data) => {
//     return apis.get(`/post/comment/replycount/${data.commentId}` ,{},{
//       headers: { Authorization: `Bearer ${data.token}`},
//     })
//   }

//   const postReplyComment = (data) => {
//     return apis.post(`/post/comment/reply/${data.commentId}` ,data, {
//       headers: { Authorization: `Bearer ${data.token}` },
//     });
// }

// //--------------- LIKED POST API CALLS ---------------------------------------------------
//   const getMyLikedPostId = (data) => {
//     return apis.get(
//       `/post/like/mylikesId`,{},
//       { headers: { Authorization: `Bearer ${data.token}` } }
//     );
// };

// const getPostILiked = (data) => {
//   // console.log(data);
//   return apis.get(`/post/like/postLikedByUser`,
//   {},
//   { headers: { Authorization: `Bearer ${data.token}` } }
//   );
// }

// //----------------- VIEWED POST API CALLS -----------------------------------------------
// const postViewed = (data) => { 
//   return apis.post(`/post/view/${data.postId}` , {},
//   { headers: { Authorization: `Bearer ${data.token}` } }
//   )
// }

// const getViewedPost = (data) => {
//   return apis.get(`/post/view/myViewedId` , {},
//   { headers: { Authorization: `Bearer ${data.token}` } }
//   )
// }

// const viewCount = (data) => {
//   return apis.get(`/post/view/myViewedCount` , {},
//   { headers: { Authorization: `Bearer ${data.token}` } }
//   )
// }

//   //-------------- USER WALLET DATA API CALLS -------------------------------------------
//   const getWallet = (data) => {
//     return apis.get(`/users/home/getmywallets`, {} , 
//     { headers: { Authorization: `Bearer ${data.token}` } })
//   }

//   const getWalletAccounts = (data) => {
//     return apis.get(`/users/home/getmywalletaccounts/${data.walletId}`, {} , 
//     { headers: { Authorization: `Bearer ${data.token}` } })
//   }

//   const getBalance = (data) => {
//     return apis.get(`/users/profile/balance1?address=${data.address}&tokenId=${data.tokenId}`, {} , 
//     { headers: { Authorization: `Bearer ${data.token}` } }
//     )
//   }

//   const transaction = data => {
//     const { token } = data;
//     delete data["token"];
//     return apis.post(`/users/profile/transferbalanceortoken`, data , 
//     { headers: { Authorization: `Bearer ${token}` }}
//     )
//   }

//   const getMyNFT = ({ token }) => {
//     return apis.get(
//       `/users/home/myNFTFeeds?orderBy=id&sort=ASC`,
//       {},
//       { headers: { Authorization: `Bearer ${token}` }}
//     );
//   };

//   const transactionHistory = ({ walletId  , token , transactionType , status }) => {
//     if(transactionType){
//     return apis.get(
//       `/users/profile/AllTransactions/${walletId}?transactionType=${transactionType}` , {} ,
//     { headers: { Authorization: `Bearer ${token}` } }
//     )}
//     else{
//       return apis.get(
//         `/users/profile/AllTransactions/${walletId}` , {} ,
//       { headers: { Authorization: `Bearer ${token}` } }
//       )
//     } 
//   }

//   const getRewards = ({ walletId , token }) => {
//     return apis.get(
//       `/users/profile/AllMyRewardHistory/${walletId}`, {} , 
//     { headers: { Authorization: `Bearer ${token}` } }
//     )
//   }

//   //----------------- USER PROFILES API CALLS --------------------------------------------
//   const getProfiles = (data) => {
//     if(data.search){
//       return apis.get(
//         `/users/home/recent-profiles?search=${data.search}`, {} , 
//       { headers: { Authorization: `Bearer ${data.token}` } }
//       )
//     }
//     return apis.get(
//       `/users/home/recent-profiles`, {} , 
//     { headers: { Authorization: `Bearer ${data.token}` } }
//     )
//   }

//   const followRequest = (data) => {
//     return apis.post(
//       `/follow/followuser/${data.userId}` , {},
//       { headers: { Authorization: `Bearer ${data.token}` } }
//     ) 
//   } 

//   const unFollowRequest = (data) => {
//     return apis.delete(
//       `/follow/unfollow/${data.userId}` , {},
//       { headers: { Authorization: `Bearer ${data.token}` } }
//     ) 
//   }

//   const getFollowingId = (data) => {
//     return apis.get(
//       `/follow/myfollowingsId` , {},
//       { headers: { Authorization: `Bearer ${data.token}` } }
//     ) 
//   }

//   const getAllFollowers = (data) => {
//     return apis.get(
//       `/follow/myfollowers?relations=["source"]&where={"status":"acc"}` , {},
//       { headers: { Authorization: `Bearer ${data.token}` } }
//     ) 
//   }

//   const getAllFollowing = (data) => {
//     return apis.get(
//       `/follow/myfollowings?relations=["target"]&where={"status":"acc"}` , {},
//       { headers: { Authorization: `Bearer ${data.token}` } }
//     ) 
//   }

//   const getFollowersById = (data) => {
//     return apis.get(
//       `/follow/userfollowers/${data.userId}?relations=["source"]&where={"status":"acc"}` , {},
//       { headers: { Authorization: `Bearer ${data.token}` } }
//     ) 
//   }

//   //------------------ DISCOVER PAGE ---------------------------------------------------
//   const explore = (data) => {
//     if(data.search){
//       return apis.get(
//         `/users/home/Explore?search=${data.search}`, {} , 
//       { headers: { Authorization: `Bearer ${data.token}` } }
//       )
//     }
//     return apis.get(
//       `/users/home/Explore`, {} , 
//     { headers: { Authorization: `Bearer ${data.token}` } }
//     )
//   }
  
//   const getAllHashtag = (data) => {
//     // console.log("called")
//      return apis.get(
//        `/post/hashTag/hashTag` , {},
//        { headers: { Authorization: `Bearer ${data.token}` } }
//      )
//   }
  
//   // CATEGORIES

//   const getCategories = (data) => {
//     return apis.get(`/post/category/getCategory`, {} , 
//     { headers: { Authorization: `Bearer ${data.token}` } }
//     )
//   }

//   //----------- VIDEOS ------------------------------------
//   const hideVideo = ({ id, token }) => {
//     // console.warn("function called")
//     return apis.post(
//       `/post/hidePost/${id}`, {} , 
//     { headers: { Authorization: `Bearer ${token}` } }
//     )
//   };

//   const unhideVideo = ({ id, token }) => {
//     // console.warn("function called")
//     return apis.patch(
//       `/post/unhidePost/${id}`, {} , 
//     { headers: { Authorization: `Bearer ${token}` } }
//     )
//   };

//   const editPutonsale = ({ id, token,data }) => {
//     // console.warn("function called")
//     return apis.patch(
//       `/post/editPutonSale/${id}`, data , 
//     { headers: { Authorization: `Bearer ${token}` } }
//     )
//   };

//   const getHiddenPosts = ({ token }) => {
//     // console.warn("function called")
//     return apis.get(
//       `/post/getMyhiddenPosts`, {} , 
//     { headers: { Authorization: `Bearer ${token}` } }
//     )
//   };


//   // NFT
//   const mintNFT = ({ id, token }) => {
//     // console.warn("function called")
//     return apis.post(
//       `/post/mintNFT/${id}`, {} , 
//     { headers: { Authorization: `Bearer ${token}` } }
//     )
//   };

//   const NFTSale = ({postId , token , amount}) => {
//     // console.log(postId, amount , token , "FROM API SALE")
//     return apis.post(
//       `/post/PutNFTOnsale/${postId}`, {amount}, 
//     { headers: { Authorization: `Bearer ${token}` } }
//     )
//   }

//   const NFTBuy = ({ token ,from , to, tokenId, amount , id }) => {
//     return apis.post(
//       `/users/profile/BuyNft`, { from , to, tokenId, amount , id }, 
//     { headers: { Authorization: `Bearer ${token}` } }
//     )
//   }

//   //NOT FOR FINAL APP
//   const deleteUser = ({ id }) => {
//     return apis.delete(`/auth/deleteUser/${id}`);
//   };



  return {
    signUp,
    signIn,
    verifyotp,
    resendOtp,
    getUser,
    forgotpassword,
    setnewpassword,
    editProfile,
    profilePicture,
    deleteProfilePicture,
    updateProfilePicture,
    getInfo,
    getCountries
    
    // getMyFeed,
    // getMyPost,
    // getPostInfo,
    // createPost,
    // editPost,
    // deletePost,
    
    // likePost,
    // postLikeCountById,

    // postViewed,
    // getViewedPost,
    // getPostById,
   
    // getCommentById,
    // getCommentCount,
    // postComment,
    // deleteComment,

    // getCommentLike,
    // getCommentLikeCount,
    // likeComment,
    // getProfileLikeComment,
    // getLikedCommentId,

    // getCommentReply,
    // replyCommentCount,
    // postReplyComment,

    // getMyLikedPostId,
    // getPostILiked,
    // getAllHashtag,

    // followRequest,
    // unFollowRequest,
    // getFollowingId,
    // getAllFollowers,
    // getAllFollowing,
    // getFollowersById,
    // getFollowerFeed,

    // getWallet,
    // getWalletAccounts,
    // getBalance,
    // transaction,
    // transactionHistory,
    // getRewards,
  
    // getProfiles,
    // explore,

    // deleteUser,

    // getCategories,

    // mintNFT,
    // NFTSale,
    // NFTBuy,

    // hideVideo,
    // unhideVideo,
    // getHiddenPosts,
    // getMyNFT,
    // editPutonsale,
  };
};

export default { create };
