import { Provider } from 'react-redux';
import {
  BrowserRouter as Router, Route, Routes
} from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";
import './App.css';
import Form from './Component/Form/Form';
import Header from './Component/Header/Header';
import { persistor, store } from './store/index';

function App() {
  return (
    <Provider store={store}>
    <PersistGate persistor={persistor}>
        <div className="App">
          <Header/>
          <Router>
           <Routes>
             <Route path='/' element={<Form form={'email'}/>}/>
             <Route path='/otpVerify' element={<Form form={'otpVerify'}/>}/>
             <Route path='/successfullyRegistered' element={<Form form={'successfullyRegistered'}/>}/>
           </Routes>
          </Router> 
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
