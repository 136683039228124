import CustomButton from "../CustomButton/CustomButton"
import "./PopUp.css"


function PopUp({ Display , SetDisplay}) {
  if(Display){
    return (
      <div className="modalWrapper" >
        <div className="pop-up">
            <p className="popUp-text">OTP Verified Succesfully</p>
            <CustomButton
               onClick={() => SetDisplay(false)}
               buttonText={'OKAY'}
               small
            />
        </div>
      </div>
        
      )
  }
  else{
    return null
  }
}

export default PopUp