import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
//============== REDUX IMPORT ===================================================================
import error from "./ErrorRedux";

import saga from "./SagaIndex";
import user from "./UserRedux";


const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  user,
  error,
});

const persistedReducer = persistReducer(persistConfig, reducer);

let sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer : persistedReducer,
  middleware: [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
      immutableCheck: false,
     }),
    sagaMiddleware,
  ],
});
sagaMiddleware.run(saga);

const persistor = persistStore(store);


export { persistor, store };


