import { createSlice } from "@reduxjs/toolkit";

const errorslice = createSlice({
    name: "error",
    initialState: {
        errorLoading : false,
        error : null,
    },
    reducers : {
        errorTrigger : (state , action) => {
            return{
                ...state,
                errorLoading : action.payload,
            }
        },
        errorFinished : (state , action) => {
            return{
                ...state,
                error : action.payload,
            }
        },
        clearError : (state , action) => {
            return {}
        }
    },
})

export default errorslice.reducer;

export const {
   errorTrigger,
   errorFinished,
   clearError,
} = errorslice.actions;