import { call, put } from "redux-saga/effects";
import Api from "../services/ApiCaller";
import { errorFinished, errorTrigger } from "./ErrorRedux";
import { store } from "./index";
import {
  OTPSuccess,
  OTPVerifyTrigger, resendOtpTrigger, signInFail, signInSuccess, signInTrigger, signUpFail,
  signUpSuccess,
  signUpTrigger
} from "./UserRedux";

export function* SignUp(api, { payload = {} }) {
  yield put(signUpTrigger(true));
  const { email , navigate } = payload;
  try {
    let data = {
      email,
      password : "random@12345",
      loginType : "email",
      role: "User",
      sendVerification: true,
    };
    const result = yield call(Api.callServer, api.signUp, data, true);
    yield put(signUpSuccess(result));
    navigate('/otpVerify');
   
  } 
  catch(errorPayload){
    if(errorPayload.message === "Not Verified"){
      navigate('/otpVerify')
    }
    if(errorPayload.message == "EMAIL_USED"){
      store.dispatch({ type: "LogInCalled", payload: {
        email,
        password : "random@12345",
        loginType : "email",
        role: "User",
        sendVerification: true,
        navigate
      }})
      navigate('/otpVerify')
    } 
    yield put(signUpFail(errorPayload.message));
  } 
  finally {
    yield put(signUpTrigger(false));
  }
}

export function* VerificationOtp(api, { payload = {} }) {
  yield put(OTPVerifyTrigger(true));
  try {
    const { email, Otp , navigate } = payload;
    const result = yield call(Api.callServer,api.verifyotp,{ email, Otp },true);
    yield put(OTPSuccess(result));
    navigate('/successfullyRegistered')
  } 
  catch (error) {
    yield put(errorTrigger(true))
    yield put(errorFinished(error.message));
  }
  finally {
    yield put(OTPVerifyTrigger(false));
  }
}

export function* resendOTP(api, { payload = {} }) {
  yield put(resendOtpTrigger(true));
  try {
    const { email } = payload;
    const result = yield call(Api.callServer, api.resendOtp, { email }, true);
    console.warn(result);
  } 
  catch(e){
    console.log(e , ":Error ResendOTP")
  } 
  finally {
    yield put(resendOtpTrigger(false));
  }
}

// export function* editUserName(api, { payload = {} }) {
//   yield put(editUserTrigger(true));
//   let { userName , navigate } = payload;
//   try {
//     let token = yield getAccessToken();
//     const result = yield call(Api.callServer, api.editProfile, { userName , token }, true);
//     // navigation.navigate(SCREEN_KEYS.BIO);
//     yield put(editUserSuccess(result));
//     navigate('/aboutBio')
//     // let userId = yield getUserId();
//     // yield put(profileInfoSuccess(result3));
//    //console.warn(result);  
//   } 
//   catch (error) {
//    // console.log(error , "USERNAME SAGA");
//     yield put(errorTrigger(true))
//     yield put(errorFinished(error.message));
//   }
//   finally{
//     yield put(editUserTrigger(false));
//   }
// }

export function* LogIn(api, { payload = {} }) {
  yield put(signInTrigger(true));
  const { navigate, email, password, loginType } = payload;
  try {
    let data = { email, password, loginType };
    const result = yield call(Api.callServer, api.signIn, data, true);
    yield put(signInSuccess(result));
    //console.log(result , "FROM LOG IN 1 ================================================")
  } 
  catch (errorPayload) {
    yield put(signInFail(errorPayload.message));
    if(errorPayload.message == "emailNotVerified"){
      // navigate('/otpVerify')
    }
    yield put(errorTrigger(true))
    yield put(errorFinished(errorPayload.message));
    console.error( errorPayload , "ERROR LOGIN SAGA");
  } 
  finally { 
    yield put(signInTrigger(false));
    yield put(signInFail(""))
  }
}

// export function* ForgotPassword(api, { payload = {} }) {
//   yield put(forgotPasswordTrigger(true));
//   let erromsg = "";
//   try {
//     const { loginType, navigation, email } = payload;
//     let data = {
//       loginType,
//       email,
//     };
//     const result = yield call(Api.callServer, api.forgotpassword, data, true);
//     yield put(forgotPasswordSuccess(result));
//     navigation.navigate(SCREEN_KEYS.SETNEWPASSWORD , { email });
//   } 
//   catch (e) {
//     if (e.message == "limitexceed") {
//       erromsg = "Limit exceed,try after some time ";
//     }
//     yield put(forgotPasswordFail(erromsg));
//   } 
//   finally {
//     yield put(forgotPasswordTrigger(false));
//   }
// }

// export function* SetNewPassword(api, { payload = {} }) {
//   yield put(passwordChangeTrigger(true));
//   try { 
//     const { email, password : newPassword ,  Otp : otp, navigation } = payload;
//     const result = yield call(Api.callServer,api.setnewpassword,{ email, otp, newPassword },true);
//     yield put(passwordChangeSuccess(result))
//     console.warn(result);
//     navigation.replace(SCREEN_KEYS.SIGNIN);
//   } 
//   catch (error) {
//     yield put(passwordChangeFail(error.message))
//     console.error(error);
//     // alert(error.message);
//   } 
//   finally {
//     yield put(passwordChangeTrigger(false));
//   }
// }

// export function* GetUser(api, { payload = {} }) {
//   yield put(profileTrigger(true));
//   try {
//     let token = yield getAccessToken()
//     const result = yield call(Api.callServer, api.getUser, { token }, true);
//     // console.warn(result)
//     yield put(profileSuccess(result));
//   } catch (error) {
//     yield put(profileFail(error));
//     // console.error(error)
//   } finally {
//     yield put(profileTrigger(false));
//   }
// }

// export function* EditProfile(api, { payload = {} }) {
//   yield put(editUserTrigger(true));
//   let { item, navigate } = payload;
//   let token = yield getAccessToken();
//   try {
//     let data = {...item, token};
//     const result = yield call(Api.callServer, api.editProfile, data, true);
//     yield put(editUserSuccess(result));
//     //console.warn(result);
    
//       //LOADING USER PROFILE SCREEN DETAILS 
//       let userId = yield getUserId()
//       const result3 = yield call(Api.callServer, api.getInfo, { token , userId }, true);
//       //yield put(profileInfoSuccess(result3));
//       console.log(result3 , "FROM LOG IN 3 ================================================")
//       yield delay(1000)
//       navigate('/successfullyRegistered');
//   } 
//   catch(error){
//     console.error(error)
//   }
//   finally {
//       yield put(editUserTrigger(false));
//   }
// }

// export function* updateProfileImage(api, { payload = {} }) {
//   yield put(updateProfileImageTrigger(true));
//   try {
//     const { image } = payload;
//     let token = yield getAccessToken();
//     const result = yield call(Api.callServer, api.updateProfilePicture,{ image, token },true);
//     // const result2 = yield call(Api.callServer, api.getUser, { token }, true);
//     // yield put(profileSuccess(result2));
//     yield put(updateProfileImageSuccess(result));
//     const userId = yield getUserId()
//     const result2 = yield call(Api.callServer, api.getInfo, { token , userId }, true);
//     yield put(getInfo(result2));
//     //console.warn(result, result2);
//   } 
//   catch(error){
//     console.error(error.message);
//   } 
//   finally {
//     yield put(updateProfileImageTrigger(false));
//   }
// }

// export function* LogoutUser(api, { payload = {}}) {
//   const { navigation } = payload;
//   try {
//     navigation.replace(SCREEN_KEYS.SIGNIN)
//     yield delay(2000)
//     yield put(clearWalletData(''));
//     yield put(clearFeedData());
//     yield put(clearPostData());
//     yield put(clearProfile());
//     yield put(clearError());
//     yield put(clearOther());
//     yield put(logoutUserSuccess());       
//  } 
//  catch (e) {
//     console.log(e , "LOGOUT ERROR")
//   } 
//   finally {
//   }
// }

//NOT FINAL APP
// export function* deleteUser(api, { payload = {} }) {
//   yield put(deleteUserTrigger(true));
//   try {
//     const { id } = payload;
//     const result = yield call(Api.callServer, api.deleteUser, { id }, true);
//     console.log(result);
//     yield put(deleteUserSuccess(true));
//   } catch (e) {
//     yield put(deleteUserFail(e));
//   } finally {
//     yield put(deleteUserTrigger(false));
//   }
// }



// export function* GetUserInfo(api , {payload = {}}){
//   yield put(infoTrigger(true))
//   try {
//     const token = yield getAccessToken(); 
//     const userId = yield getUserId()
//     const result = yield call(Api.callServer, api.getInfo, { token , userId }, true);
//     yield put(getInfo(result));
//     // console.warn(result , "RESULT GetInfo")
//   } 
//   catch (error) {
//     //console.error(error , " ERROR GetInfo")
//   } finally {
//     yield put(infoTrigger(false))
//   }
// }

// export function* GetUserOthersInfo(api , { payload = {} }){
//   yield put(othersInfoTrigger(true))
//   const userId = payload 
//   try {
//     const token = yield getAccessToken();
//     const result1 = yield call(Api.callServer, api.getFollowingId, { token }, true);
//     yield put(getFollowingIdSuccess(result1));

//     const result = yield call(Api.callServer, api.getInfo, { token , userId }, true);
//     yield put(othersInfo(result));
//     //console.warn(result , "RESULT GetOthersInfo")
//   } 
//   catch (error) {
//     //console.error(error , " ERROR GetOthersInfo")
//   } finally {
//     yield put(othersInfoTrigger(false))
//   }
// }

// export function* getMyLikedPost(api, { payload = {} }) {
//   try {
//     const token = yield getAccessToken();
//     const result = yield call(Api.callServer, api.getMyLikedPost, { token }, true);
//     yield put(likesmyId(result));
//   } 
//   catch (error) {
//     console.error(error , " ERROR GetMylikedPost")
//   } finally {
//   }
// }

//========= POST RELATED METHODS MOVED TO POST SAGA ==================================================
// export function* MintNft(api, { payload = {} }) {
//   yield put(mintNftTrigger(true));
//   try {
//     const { id } = payload;
//     const token = yield getAccessToken();
//     const result = yield call(Api.callServer, api.mintNFT, { id, token }, true);
//     showToast("Successfully minted for NFT")
//     yield put(mintNftSuccess(result));
//   } catch (e) {
//     yield put(mintNftError(e));
//   } finally {
//     yield put(mintNftTrigger(false));
//   }
// }

// export function* HideVideo(api, { payload = {} }) {
//   yield put(hideVideoTrigger(true));
//   yield put(getHiddenPostTrigger(true));
//   try {
//     const { id } = payload;
//     const token = yield getAccessToken();
//     const result = yield call(Api.callServer, api.hideVideo, { id, token }, true);
//     yield put(hideVideoSuccess(result));
//     showToast("Video Hided Succesfully")
//     const result2 = yield call(Api.callServer, api.getHiddenPosts, { token }, true);
//     yield put(getHiddenPostSuccess(result2));
//   } catch (e) {
//     yield put(hideVideoError(e));
//   } finally {
//     yield put(hideVideoTrigger(false));
//     yield put(getHiddenPostTrigger(false));
//   }
// }

// export function* unhidePost(api, { payload = {} }) {
//   yield put(hideVideoTrigger(true));
//   yield put(getHiddenPostTrigger(true));
//   try {
//     const { id } = payload;
//     const token = yield getAccessToken();
//     const result = yield call(Api.callServer, api.unhideVideo, { id, token }, true);
//     yield put(hideVideoSuccess(result));
//     showToast("Video unhided Succesfully")
//     const result2 = yield call(Api.callServer, api.getHiddenPosts, { token }, true);
//     yield put(getHiddenPostSuccess(result2));
//   } catch (e) {
//     yield put(hideVideoError(e));
//   } finally {
//     yield put(hideVideoTrigger(false));
//     yield put(getHiddenPostTrigger(false));
//   }
// }

// export function* getHiddenPost(api, { payload = {} }) {
//   console.log("sgag is called")
//   yield put(getHiddenPostTrigger(true));
//   try {
//     const token = yield getAccessToken();
//     const result = yield call(Api.callServer, api.getHiddenPosts, { token }, true);
//     console.log(result, "HiddenPosts")
//     yield put(getHiddenPostSuccess(result));
//   } catch (e) {
//     yield put(getHiddenPostError(e));
//     console.log(e, 'ERROR FROM HIDDEN POSTS')
//   } finally {
//     yield put(getHiddenPostTrigger(false));
//   }
// }

//====================== MOVED TO PROFILE SAGA ======================================
// export function* FollowRequest(api, { payload = {} }) {
//   let { userId } = payload
//   yield put (followTrigger(true))
//   try {
//     const token = yield getAccessToken();
//     const result = yield call(Api.callServer, api.followRequest, { token  , userId }, true);
//     yield put(followSuccess(result));
//     yield put(followFullFil(true))
//     //console.warn(result , "RESULT FollowRequest")
//     const result2 = yield call(Api.callServer, api.getFollowingId, { token }, true);
//     yield put(getFollowingIdSuccess(result2));

//     const result1 = yield call(Api.callServer, api.getInfo, { token , userId }, true);
//     yield put(othersInfo(result1)); 
//   } 
//   catch (error) {
//     //console.error(error , " ERROR FollowRequest")
//   } finally {
//     yield put (followTrigger(false))
//     yield put(followFullFil(false))
//   }
// }

// export function* UnfollowRequest(api, { payload = {} }) {
//   let { userId } = payload
//   yield put (unFollowTrigger(true))
//   try {
//     const token = yield getAccessToken();
//     const result = yield call(Api.callServer, api.unFollowRequest, { token  , userId }, true);
//     yield put(unFollowSuccess(result));
//     //console.warn(result , "RESULT unfollowRequest")
//     yield put(unFollowFullFil(true))

//      const result2 = yield call(Api.callServer, api.getFollowingId, { token }, true);
//     yield put(getFollowingIdSuccess(result2));

//     const result1 = yield call(Api.callServer, api.getInfo, { token , userId }, true);
//     yield put(othersInfo(result1));
//   } 
//   catch (error) {
//    // console.error(error , " ERROR unfollowRequest")
//   } finally {
//     yield put (unFollowTrigger(false))
//     yield put(unFollowFullFil(false))
//   }
// }

// export function* GetMyFollowingIds(api, { payload = {} }) {
//   yield put (getFollowingIdTrigger(true))
//   try {
//     const token = yield getAccessToken();
//     const result = yield call(Api.callServer, api.getFollowingId, { token }, true);
//     yield put(getFollowingIdSuccess(result));
//     // console.warn(result , "RESULT GetMyFollowingIds")
//   } 
//   catch (error) {
//     // console.error(error , " ERROR GetMyFollowingIds")
//   } finally {
//     yield put (getFollowingIdTrigger(false))
//   }
// }

// export function* GetMyFollowing(api, { payload = {} }) {
//   yield put (getFollowingTrigger(true))
//   try {
//     const token = yield getAccessToken();
//     const result = yield call(Api.callServer, api.getAllFollowing, { token }, true);
//     yield put(getFollowingSuccess(result));
//     // console.warn(result , "RESULT GetMyFollowing")
//   } 
//   catch (error) {
//     //console.error(error , " ERROR GetMyFollowing")
//   } finally {
//     yield put (getFollowingTrigger(false))
//   }
// }

// export function* GetMyFollowers(api, { payload = {} }) {
//   yield put (getFollowerTrigger(true))
//   try {
//     const token = yield getAccessToken();
//     const result = yield call(Api.callServer, api.getAllFollowers, { token }, true);
//     yield put(getFollowerSuccess(result));
//    // console.warn(result , "RESULT GetMyFollowing")
//   } 
//   catch (error) {
//    // console.error(error , " ERROR GetMylikedPost")
//   } finally {
//     yield put (getFollowerTrigger(false))
//   }
// }

// export function* GetProfiles(api, { payload = {} }) {
//   yield put (getProfilesTrigger(true))
//   try {
//     let { search } = payload
//     const token = yield getAccessToken();
//     const result = yield call(Api.callServer, api.getProfiles, { token , search }, true);
//     yield put(getProfilesSuccess(result));
//     // console.warn(result , "RESULT GetProfiles")

//   } 
//   catch (error) {
//     //console.error(error , "ERROR GetProfiles")
//   } finally {
//     yield put (getProfilesTrigger(false))
//   }
// }
