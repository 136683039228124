import { put } from "redux-saga/effects";
import { errorFinished, errorTrigger } from "./ErrorRedux";

export function* RemoveError(api, { payload = {} }) {
    yield put(errorTrigger(true))
    try{
        yield put(errorFinished(null))
    }
    finally{
        yield(errorTrigger(false))
    }
}