import './Header.css'
import galaxiiLogo from '../../asset/logo/GalaxiiLogo.svg'

function Header() {
  return (
    <div className='header'>
      <a href='/' style={{textDecoration : 'none'}} >
        <div className='logo'>
          <div className='svg'>
            <img src={galaxiiLogo} alt="" className='galaxiiLogo' />
          </div>
          <h4 className='logo-text'>GALAXII</h4>
        </div>
      </a>
      
     <h1 className='header-text'>Sign Up For Galaxii Beta</h1>
    </div>
  )
}

export default Header