import { all, takeEvery } from "redux-saga/effects";
import API from "../services/Api";
import { RemoveError } from "./ErrorSaga";
import {
  LogIn,
  resendOTP,
  SignUp,
  VerificationOtp
} from "./UserSaga";

const api = API.create();
export default function* root() {
  yield all([
    // USER RELATED ACTIONS
    takeEvery("SignUpCalled", SignUp, api),
    takeEvery("LogInCalled" , LogIn , api),
    takeEvery("OtpVerifyCalled", VerificationOtp, api),
    takeEvery("ResendOtpCalled", resendOTP, api),
    takeEvery("RemoveErrorCalled" , RemoveError , api),
  ]);
}
