import * as _ from "lodash";
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import CustomButton from "../CustomButton/CustomButton";
import CustomInput from "../CustomInput/CustomInput";
import "./form.css";

import Loader from "../Loader/Loader";
import PopUp from "../PopUp/PopUp";


function Form({ form ,SignUp , Verify , Auth ,  Error , ClearError , signUpLoading , otpVerifyLoading }) {
  useEffect(() => {
    ClearError(); 
    setDisplay(false)
  }, [form])



  
  //const [signUp , setSignUp ] = useState(false)
  let navigate = useNavigate()
  const [email , setEmail ] = useState('')
  const [otp , setOTP] = useState(new Array(4).fill(''))
  
  const [display , setDisplay ] = useState(Auth)

  const onSignUp = (e) => {
      e.preventDefault()
      SignUp({email , navigate})
  }

  const handleChange = (element , index) => {
     if(isNaN(element.value)) return false;

     setOTP([...otp.map((d  , idx)=> (idx === index) ? element.value : d)]);

     if(element.nextSibling){
        element.nextSibling.focus();
     }
  }

  const onVerify = (e) => {
    e.preventDefault()
    let Otp = otp.join('')
    Verify({email, Otp , navigate})
  }

  

  
  return (
    <form className={`form-body ${display && 'hideScroll'}`}>
      {
        display &&
        <PopUp Display={display} SetDisplay={setDisplay}/>
      }
        <div className='form-inner'>
        { 
        (signUpLoading || otpVerifyLoading) && 
          <Loader display={signUpLoading || otpVerifyLoading}/> 
        ||
        <>
        <h1 className='form-header'>{form == 'email'  && 'Welcome' || null} {form == 'otpVerify'  && 'Welcome' || null}</h1>
        {
          form == "successfullyRegistered" &&
          <>
          <svg width="116" height="116" viewBox="0 0 116 116" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="clarity:success-standard-line" clip-path="url(#clip0_2324_380)">
            <path id="Vector" d="M57.9999 6.44434C47.8032 6.44434 37.8355 9.46802 29.3572 15.133C20.8789 20.798 14.2709 28.8499 10.3688 38.2704C6.46667 47.691 5.4457 58.0571 7.43498 68.0579C9.42427 78.0587 14.3345 87.245 21.5446 94.4552C28.7548 101.665 37.9411 106.576 47.9419 108.565C57.9427 110.554 68.3088 109.533 77.7294 105.631C87.1499 101.729 95.2018 95.1209 100.867 86.6426C106.532 78.1644 109.555 68.1966 109.555 57.9999C109.555 44.3265 104.124 31.2132 94.4552 21.5446C84.7867 11.8761 71.6733 6.44434 57.9999 6.44434ZM57.9999 103.111C49.0778 103.111 40.356 100.465 32.9375 95.5084C25.519 90.5515 19.737 83.5061 16.3227 75.2632C12.9083 67.0202 12.015 57.9499 13.7556 49.1992C15.4962 40.4485 19.7926 32.4104 26.1015 26.1015C32.4104 19.7926 40.4485 15.4962 49.1992 13.7556C57.9499 12.015 67.0202 12.9083 75.2632 16.3227C83.5062 19.737 90.5516 25.519 95.5084 32.9375C100.465 40.356 103.111 49.0778 103.111 57.9999C103.111 69.9641 98.3583 81.4383 89.8983 89.8983C81.4383 98.3582 69.9641 103.111 57.9999 103.111Z" fill="#67F8BE"/>
            <path id="Vector_2" d="M90.2221 38.9892C89.6184 38.3891 88.8017 38.0522 87.9505 38.0522C87.0992 38.0522 86.2825 38.3891 85.6788 38.9892L49.9121 74.5948L30.5788 55.2615C29.9891 54.6248 29.1707 54.2485 28.3036 54.2152C27.4364 54.182 26.5916 54.4946 25.9549 55.0842C25.3182 55.6739 24.9419 56.4923 24.9087 57.3595C24.8754 58.2266 25.188 59.0715 25.7777 59.7081L49.9121 83.7781L90.2221 43.5648C90.5241 43.2653 90.7639 42.9089 90.9274 42.5162C91.091 42.1236 91.1753 41.7024 91.1753 41.277C91.1753 40.8517 91.091 40.4305 90.9274 40.0378C90.7639 39.6452 90.5241 39.2888 90.2221 38.9892Z" fill="#67F8BE"/>
            </g>
            <defs>
            <clipPath id="clip0_2324_380">
            <rect width="116" height="116" fill="white"/>
            </clipPath>
            </defs>
          </svg>
          <h1 className="thank-you-header">{(form  == "successfullyRegistered") && 'Thank You For Registering For Galaxii Beta !'} </h1>
          </>
        }
       
        <div className='form-group'>
             { 
              form == 'email' && 
                <CustomInput 
                   text={'Email Id'}
                   label={'email'} 
                   placeholder={'Please enter email address'} 
                   value={email} 
                   onChange={e => setEmail(e.target.value)}
                   Error={Error}
                   onFocus={() => ClearError()}
                /> 
             }
             { form == 'otpVerify' &&
               <div className="otp-form">
               <p className='label-text'>Enter the 4-Digit code sent to your registered Email address {email}</p>
                <div className='input-otp-block'>
                  {otp.map((data , index) => {
                    return(
                      <input className='otpfield'
                       name='otp' type='text'
                        maxLength='1' key={index} 
                        value={data} 
                        onChange={e => handleChange(e.target , index)}
                        onFocus={e => e.target.select()}  
                      />
                    )
                  })}
                  {Error == "INVALID_VERI_DETAILS" && <p className="OtpError">OTP Do Not Match</p>}
                  </div>
                </div>
              }
              
          </div>
          
         {
         form != 'successfullyRegistered' &&
          <CustomButton  
            onClick={form == 'email' ? onSignUp : form == 'otpVerify' && onVerify }
            buttonText={form == 'email' ? 'Sign Up' : form == 'otpVerify' && 'Verify'} 
          />
        
        }
          </>
          }
          </div>
    </form>
  )
}

const mapStateToProps = ({  user = {}  , error = {} , other = {}}) => {
  const signUpLoading = _.get(user, "signUpLoading", false);
  const otpVerifyLoading = _.get(user , "otpverifyloading" , false);

  const signUpError = _.get(user, "signUpError", "");

  const Auth = _.get(user , "auth" ,false)

  const Error = _.get(error ,"error" , null);

  return { signUpLoading, signUpError , Auth , Error , otpVerifyLoading };
};

const mapDispatchToProps = (dispatch) => ({
  SignUp: (data) => dispatch({ type: "SignUpCalled", payload: data }),
  Verify: (data) => dispatch({ type: "OtpVerifyCalled" , payload: data}),
  ClearError : (data) => dispatch({ type : "RemoveErrorCalled" , payload : data}),
});

export default connect(mapStateToProps , mapDispatchToProps)(Form)

