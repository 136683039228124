import { ClipLoader } from "react-spinners"
import "./Loader.css"


function Loader({ display }) {
    if(display)
    {
        return(
            <div className="loader">
              <ClipLoader />
            </div>
        )
    }
    else{
        return null
    }
  
}

export default Loader